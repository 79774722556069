import "./currentWeather.css";

const CurrentWeather = ( {data} ) => {
  return (
    <div className="weather">
      <div className="top">
        <div>
        <p className="city">{data.city}</p>
        <p className="weather-description">{data.weather[0].main}</p>

        </div>
        <img alt="weather" className="weather-icon" src={`images/icons/${data.weather[0].icon}.png`} />
      </div>
      <div className="bottom">
        <p className="temperature">{parseInt(data.main.temp)}°F</p>
        <div className="details">
            <div className="parameter-row">
                <span className="parameter-label">Details</span>
            </div>
            <div className="parameter-row">
                <span className="parameter-label">Feels like</span>
                <span className="parameter-value">{data.main.feels_like}°F</span>
            </div>
            <div className="parameter-row">
                <span className="parameter-label">Winds</span>
                <span className="parameter-value">{data.wind.speed}m/s</span>
            </div>
            <div className="parameter-row">
                <span className="parameter-label">Humidity</span>
                <span className="parameter-value">{data.main.humidity}%</span>
            </div>
            <div className="parameter-row">
                <span className="parameter-label">Pressure</span>
                <span className="parameter-value">{data.main.pressure/10} kPa</span>
            </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentWeather;
